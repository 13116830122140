import { Sku } from 'models/sku.model';
import { Fragment, useMemo } from 'react';
import RetailerListItem from './RetailerListItem';
import { css } from '@emotion/css';
import { Row, Col } from '@prezzee/grid';
import Typography from '@prezzee/typography';

const style = {
  spacing: css`
    margin-top: 12px !important;
  `,
};

interface RetailerListProps {
  retailers: Sku[];
  searchTerm: string;
}

const RetailerList = ({ retailers, searchTerm }: RetailerListProps) => {
  const { filteredCards, similarCards } = useMemo(() => {
    const searchRegex = new RegExp(searchTerm);

    if (searchTerm !== '') {
      return {
        filteredCards: retailers.filter(sku => searchRegex.test(sku.searchableTerms.toLocaleLowerCase())),
        similarCards: retailers.filter(sku => searchRegex.test(sku.similarTerms.toLocaleLowerCase())),
      };
    }
    return {
      filteredCards: [],
      similarCards: [],
    };
  }, [searchTerm]);

  const cards = filteredCards?.length ? filteredCards : retailers;

  return (
    <Fragment>
      {searchTerm !== '' ? (
        filteredCards?.length === 0 ? (
          <Fragment>
            <Typography variant="subtitle2" id="retailer-list-dialog-description">
              This retailer isn&apos;t available to swap for with this Smart eGift Card
            </Typography>
            <Typography className={style.spacing}>Check out the available selection</Typography>
          </Fragment>
        ) : (
          <Typography variant="subtitle2" id="retailer-list-dialog-description">
            Your results
          </Typography>
        )
      ) : null}

      <Row wrap="wrap">
        {cards.map(sku => (
          <Col key={sku.uid} sm={6} className={style.spacing}>
            <RetailerListItem title={sku?.displayName} img={sku.themes?.[0]?.image} />
          </Col>
        ))}
      </Row>

      {similarCards?.length > 0 && filteredCards?.length > 0 && (
        <Fragment>
          <Typography className={style.spacing}>Also available with this eGift Card</Typography>
          <Row wrap="wrap">
            {similarCards.map(sku => (
              <Col key={sku.uid} sm={6} className={style.spacing}>
                <RetailerListItem title={sku?.displayName} img={sku.themes?.[0]?.image} />
              </Col>
            ))}
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};

export default RetailerList;
