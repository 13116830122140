import Typography from '@prezzee/typography';
import { colors, spacing } from '@prezzee/tokens';
import { css } from '@emotion/css';
import CmsImage from 'components/CmsImage';

const styles = {
  container: css`
    display: flex;
    background-color: ${colors.lilac50};
    border-radius: 4px;
    padding: ${spacing.spacing03} ${spacing.spacing04};
    height: 100%;
    align-items: center;
  `,
  img: css`
    border-radius: 4px;
    width: 38px;
    height: 24px;
    box-shadow: 0px 13px 15px -4px rgba(0, 0, 0, 0.13);
    margin-right: 12px;
  `,
  title: css`
    color: ${colors.gray800} !important;
  `,
};

interface RetailerListItemProps {
  title: string;
  img: string;
}

const RetailerListItem = ({ title, img }: RetailerListItemProps) => {
  return (
    <div className={styles.container}>
      {img && <CmsImage src={img} className={styles.img} alt={title} width={38} height={24} />}
      <Typography className={styles.title}>{title}</Typography>
    </div>
  );
};

export default RetailerListItem;
