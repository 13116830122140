// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cn from "rescript-classnames/src/Cn.bs.js";
import * as BsCss from "../bindings/BsCss.bs.js";
import * as Curry from "@rescript/std/lib/es6/curry.js";
import * as JsxRuntime from "react/jsx-runtime";

var container = Curry._1(BsCss.style, {
      hd: BsCss.transitionProperty("transform"),
      tl: {
        hd: BsCss.transitionDuration(300),
        tl: {
          hd: BsCss.transitionTimingFunction("ease"),
          tl: {
            hd: BsCss.hover({
                  hd: BsCss.transform({
                        NAME: "scale",
                        VAL: [
                          1.05,
                          1.05
                        ]
                      }),
                  tl: /* [] */0
                }),
            tl: /* [] */0
          }
        }
      }
    });

var Styles = {
  container: container
};

function CardZoomAnimation(props) {
  var className = props.className;
  var className$1 = className !== undefined ? className : "";
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: Cn.make([
                    "mx-2",
                    container,
                    className$1
                  ])
            });
}

var make = CardZoomAnimation;

export {
  Styles ,
  make ,
}
/* container Not a pure module */
