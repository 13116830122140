import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@prezzee/ribbon-ui/lib/components/Modal';

import Box from '@prezzee/ribbon-ui/lib/components/Box';
import Typography from '@prezzee/ribbon-ui/lib/components/Typography';
import Spinner from '@prezzee/ribbon-ui/lib/components/Spinner';
import { Stack } from '@prezzee/ribbon-ui/lib/components/Stack';

import RetailerList from './RetailerList';
import SearchBar from './SearchBar';
import { ChangeEvent, ReactNode, useState } from 'react';

import logger from 'utils/logger';
import { exchangeStore, skuBySlug } from 'api/queries/index';
import { useQuery } from '@tanstack/react-query';

interface RetailerListModalProps {
  isOpen?: boolean;
  onClose: () => void;
  title?: string | ReactNode;
  uid?: string;
  showDefaultStore?: boolean;
}

const RetailerListModal = ({
  isOpen = true,
  onClose,
  title,
  uid,
  // This is mainly for zag cards, retailer card uid's don't return anything when passed into the exchange store API.
  // Therefore we need to rely on the default prezzee cards uid to get the default exchange store.
  showDefaultStore = false,
}: RetailerListModalProps) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { data: swappableSkuData } = useQuery({
    queryKey: ['swappableExchange'],
    queryFn: async () => await skuBySlug('prezzee'),
    enabled: showDefaultStore,
  });

  const uidToFetch = showDefaultStore ? swappableSkuData?.uid : uid;

  const {
    data: retailerData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['exchangeStore', uidToFetch],
    queryFn: async () => {
      try {
        return await exchangeStore(uidToFetch);
      } catch (error) {
        logger.error(
          {
            err: error,
            id: uid,
            source: 'SKU Exchange Store API',
            journey: 'Purchase',
          },
          `error: 'Failed to fetch Exchange Store'`
        );

        throw new Error('Failed to fetch Exchange Store');
      }
    },
    enabled: (showDefaultStore && !!swappableSkuData?.uid) || !showDefaultStore,
  });

  const handleSearchChange = (e: ChangeEvent) => {
    const target = e.currentTarget as HTMLInputElement;
    setSearchTerm(target.value.toLocaleLowerCase().replace(/[^\w\s]/g, '\\$&'));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent
        width="100%"
        height="95%"
        maxW="800"
        maxH="800px"
        aria-labelledby="retailer-list-dialog-title"
        aria-describedby="retailer-list-dialog-description"
        margin="20px"
      >
        <ModalHeader>
          {title ?? 'Swap for these retailers'}
          <Box pt="6">
            {retailerData && retailerData.length >= 16 && (
              <SearchBar placeholder="Search brands" onChange={handleSearchChange} />
            )}
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="6">
          {isLoading && (
            <Stack alignItems="center" justifyContent="center" minH="150px">
              <Spinner color="red.400" h="40px" w="40px" thickness="4px" />
            </Stack>
          )}

          {isError && (
            <Stack alignItems="center" justifyContent="center" minH="150px">
              <Typography textAlign="center" variant="h5">
                Oh no! There has been a problem fetching data, try again soon.
              </Typography>
            </Stack>
          )}

          {retailerData && <RetailerList retailers={retailerData} searchTerm={searchTerm} />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RetailerListModal;
