import { FC } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { NextSeo } from 'next-seo';

import { SeoMeta as SeoMetaProps } from 'models/Models';
import { SEOUtil } from '../../common/util.bs';

type Props = {
  meta: SeoMetaProps;
};

const SeoMeta: FC<Props> = ({ meta }) => {
  const {
    seoMetaBasicTitle,
    seoMetaBasicDescription,
    seoMetaOgTitle,
    seoMetaOgDescription,
    seoMetaOgType,
    seoMetaOgUrl,
    seoMetaOgImageAsset,
    seoMetaTwTitle,
    seoMetaTwDescription,
    seoMetaTwImageAsset,
    seoMetaTwImageAssetUrl,
  } = meta;
  const { asPath, pathname } = useRouter();
  const twImageUrl = seoMetaTwImageAssetUrl || seoMetaTwImageAsset?.file.url;

  return (
    <>
      <NextSeo
        title={seoMetaBasicTitle}
        description={seoMetaBasicDescription}
        openGraph={{
          title: seoMetaOgTitle,
          description: seoMetaOgDescription,
          type: seoMetaOgType || 'website',
          url: seoMetaOgUrl,
        }}
        twitter={{
          cardType: 'summary_large_image', // @note: summary, summary_large_image, app or player
        }}
        canonical={SEOUtil.getCanonicalUrl(asPath)}
        languageAlternates={SEOUtil.getLanguageAlternates(asPath)}
      />
      <Head>
        <meta property="og:image" content={seoMetaOgImageAsset?.file.url} />

        <meta property="twitter:title" content={seoMetaTwTitle} />
        <meta property="twitter:description" content={seoMetaTwDescription} />

        <meta property="twitter:url" content={pathname} />
        <meta property="twitter:image" content={twImageUrl} />
      </Head>
    </>
  );
};

export default SeoMeta;
