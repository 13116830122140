import { css } from '@emotion/css';
import { spacing, colors } from '@prezzee/tokens';
import { ChangeEvent } from 'react';

type SearchBarProps = {
  onChange?: (e: ChangeEvent) => void;
  defaultValue?: string;
  placeholder: string;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

const Style = {
  container: css`
    display: flex;
    flex-direction: row;
    border: 1px solid ${colors.gray300};
    border-radius: 100px;
    width: 100%;
    margin-bottom: 4px;
  `,
  icon: css`
    margin: 5px;
  `,
  input: css`
    font-size: 16px;
    border-radius: 100px;
    width: 100%;
    outline: none;
    padding-left: ${spacing.spacing02};
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: ${colors.gray600};
      opacity: 1; /* Firefox */
    }
  `,
};

const SearchIcon = (): JSX.Element => {
  return (
    <svg
      className={Style.icon}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="20" fill="#ED1E09" />
      <path
        d="M28.2345 26.6728L23.7852 22.2236C24.7481 21.0615 25.2794 19.5673 25.2794 17.9404C25.2794 14.1552 22.1583 11.0341 18.3731 11.0341C14.5548 11.0341 11.5001 14.1552 11.5001 17.9404C11.5001 21.7587 14.588 24.8466 18.3731 24.8466C19.9669 24.8466 21.461 24.3154 22.6563 23.3525L27.1056 27.8017C27.2716 27.9677 27.4708 28.0341 27.7032 28.0341C27.9024 28.0341 28.1017 27.9677 28.2345 27.8017C28.5665 27.5029 28.5665 27.0048 28.2345 26.6728ZM13.0938 17.9404C13.0938 15.0185 15.4513 12.6279 18.4063 12.6279C21.3282 12.6279 23.7188 15.0185 23.7188 17.9404C23.7188 20.8954 21.3282 23.2529 18.4063 23.2529C15.4513 23.2529 13.0938 20.8954 13.0938 17.9404Z"
        fill="white"
      />
    </svg>
  );
};

const SearchBar = ({ onChange, placeholder, onKeyDown, defaultValue }: SearchBarProps) => {
  return (
    <div className={Style.container}>
      <SearchIcon />
      <input
        className={Style.input}
        type="text"
        value={defaultValue}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </div>
  );
};

export default SearchBar;
